export enum AbstractState {
  New = 'NEW',
  Assigned = 'ASSIGNED',
  Accepted = 'ACCEPTED',
  Rejected = 'REJECTED',
  Scheduled = 'SCHEDULED',
  Withdrawn = 'WITHDRAWN',
  Pulled = 'PULLED',
  Archive = 'ARCHIVE',
}
