import React, { ReactNode } from 'react'
import { ApolloClient } from '@apollo/client/core'
import { ApolloProvider } from '@apollo/client/react/context'
import { SnackbarProvider } from 'material-ui-snackbar-provider'
import { CustomSnackbar } from './CustomSnackbar'
import { ConfirmProvider } from 'material-ui-confirm'
import { LocalizationProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import dateFnsEnglishLocale from 'date-fns/locale/en-GB'
import { TranslationContext } from './translations/Translations'

export const AppWrapper = ({
  apolloClient,
  children,
}: {
  children: ReactNode
  apolloClient: ApolloClient<any>
}) => {
  return (
    <ApolloProvider client={apolloClient}>
      <SnackbarProvider SnackbarProps={{ autoHideDuration: 4000 }} SnackbarComponent={CustomSnackbar}>
        <ConfirmProvider>
          <LocalizationProvider dateAdapter={DateFnsUtils} locale={dateFnsEnglishLocale}>
            <TranslationContext.Provider initialState={'en'}>{children}</TranslationContext.Provider>
          </LocalizationProvider>
        </ConfirmProvider>
      </SnackbarProvider>
    </ApolloProvider>
  )
}
