import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from '@apollo/client/core'
import { isSsrMode } from '../consts'
import type { Request } from 'express'
import { onError } from '@apollo/client/link/error'
import { networkStatusLink } from './network_status'
import uniqBy from 'lodash/uniqBy'
import { addLinkDomain } from '../lib/addLinkDomain'
import { AuthenticationError, ForbiddenError } from 'apollo-server-errors'
import { captureMessage } from '@sentry/nextjs'
import { SentryLink } from 'apollo-link-sentry'

let apolloClient: ApolloClient<any>

function createErrorLink(errorCallback?: (err: Error) => void) {
  return onError(({ graphQLErrors, networkError, operation }) => {
    if (operation.getContext()?.ignoreError) {
      console.log('Ignoring Errors')
      return
    }
    if (graphQLErrors)
      graphQLErrors.map(({ message, locations, path, extensions, stack }) => {
        captureMessage(message, {
          level: 'error',
          tags: {
            Source: 'ClientErrorLink',
            path: path?.join('.'),
            operationName: operation.operationName,
          },
          extra: {
            browserUrl: operation.extensions?.browserUrl,
          },
        })

        const code = extensions?.code
        const isAuthError =
          message.toLowerCase().includes('unauthenticated') || stack?.includes('AuthenticationError')
        if (errorCallback) {
          const isForbiddenError = code === 'FORBIDDEN'
          if (isAuthError) {
            errorCallback(new AuthenticationError(message))
          } else if (isForbiddenError) {
            errorCallback(new ForbiddenError(message))
          } else {
            errorCallback(new Error(message))
          }
        } else {
          if (extensions) {
            console.error(extensions)
          }
        }

        console.error(
          `[GraphQL error]: Message: ${
            isAuthError ? 'Unauthenticated' : message
          }, Location: ${locations?.join('\n')}, Path: ${path}`,
          code
        )
      })
    if (networkError) console.error(`[Network error]: ${networkError}`)
  })
}

function createApolloClient(req?: Request, onError?: (err: Error) => void) {
  const errorLink = createErrorLink(onError)
  const uri = addLinkDomain('/api/graphql')

  const apolloLink = ApolloLink.from([
    dedupFragmentsLink,
    networkStatusLink,
    new SentryLink({ uri, setTransaction: false }),
    pageUrlExtensionLink(req),
    errorLink,
    new HttpLink({
      uri,
      credentials: 'same-origin',
      headers: req?.headers,
      includeExtensions: true,
    }),
  ])
  return new ApolloClient({
    ssrMode: isSsrMode(),
    link: apolloLink,
    cache: new InMemoryCache(),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network',
      },
    },
  })
}

export function initializeApollo(initialState: any = null, req?: Request, onError?: (err: Error) => void) {
  if (!isSsrMode() && apolloClient) {
    return apolloClient
  }

  const _apolloClient: ApolloClient<any> = createApolloClient(req, onError)

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // get hydrated here
  if (initialState) {
    _apolloClient.cache.restore(initialState)
  }
  // For SSG and SSR always create a new Apollo Client
  if (isSsrMode()) return _apolloClient
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient

  return apolloClient
}

const pageUrlExtensionLink = (req?: Request) =>
  new ApolloLink((operation, forward) => {
    operation.extensions = operation.extensions || {}
    if (!req) {
      operation.extensions.browserUrl = window.location.href
    } else {
      operation.extensions.browserUrl = req.url
    }
    return forward(operation)
  })

const dedupFragmentsLink = new ApolloLink((operation, forward) => {
  operation.query = {
    ...operation.query,
    definitions: uniqBy(operation.query.definitions, (v) => {
      if (v.kind === 'FragmentDefinition') {
        return v.name
      } else {
        return Math.random()
      }
    }),
  }
  return forward(operation)
})
