export enum ProductType {
  Attendance = 'ATTENDANCE',
  AttendanceDay = 'ATTENDANCE_DAY',
  ConferenceEvent = 'CONFERENCE_EVENT',
  TourEvent = 'TOUR_EVENT',
  Transport = 'TRANSPORT',
  Flight = 'FLIGHT',
  Hotel = 'HOTEL',
  HotelNight = 'HOTEL_NIGHT',
  Other = 'OTHER',
  Partner = 'ACCOMPANYING',
  Extra = 'EXTRA',
  Modifier = 'MODIFIER',
}
