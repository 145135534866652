// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'
// import { ENVIRONMENT } from './scripts/dotenv'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

Sentry.init({
  dsn: SENTRY_DSN || 'https://62c612269b504de487fef6e08dba3da0@o1031728.ingest.sentry.io/5998522',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0,
  // environment: ENVIRONMENT.SENTRY_ENV,
  tunnel: "/api/tunnel/sen",
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
})
