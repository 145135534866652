import { Box, BoxProps, CircularProgress } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useCommonTranslations } from './Translation'
import { useAppContext } from '../layout/AppContext'
import cuid from 'cuid'
import { Alert } from '@material-ui/lab'

export const LoadingBar = () => {
  const { setLoadingStart, setLoadingEnd } = useAppContext()
  const [id] = useState(cuid())
  useEffect(() => {
    setLoadingStart?.(id)
    return () => {
      setLoadingEnd?.(id)
    }
  }, [id, setLoadingEnd, setLoadingStart])
  return null
}

export const Loading = (props: BoxProps) => {
  return (
    <Box width={'100%'} textAlign={'center'} p={2} {...props}>
      <CircularProgress />
    </Box>
  )
}

export const LoadingError = ({ boxProps, message }: { boxProps?: BoxProps; message?: string }) => {
  const t = useCommonTranslations()
  return (
    <Box width={'100%'} p={2} {...boxProps}>
      <Alert severity={'error'}>{message || t.ui.error}</Alert>
    </Box>
  )
}
