/* eslint-disable sonarjs/no-duplicate-string */
import { ProductType } from './interfaces/ProductType'
import { InvoiceType } from './interfaces/InvoiceType'
import { AbstractState } from './interfaces/AbstractState'
import { languageTag, translate, Translator } from '../../vendor/typed-intl'
import { Language } from './interfaces/Language'

const andJoin = (values: string[], and: string) => {
  const last = values.at(-1)
  const rest = values.slice(0, -1)
  return [rest.join(', '), last].filter(Boolean).join(and)
}

export const CommonFieldTranslations = translate({
  andList: (values: string[]) => andJoin(values, ' and '),
  salutation: 'Title',
  salutationsOptions: ['Prof.', 'Dr.', 'Mr.', 'Ms.'],
  firstName: 'First Name',
  middleName: 'Middle Name',
  lastName: 'Last Name',
  email: 'Email',
  address1: 'Address Line 1',
  address2: 'Address Line 2',
  city: 'City',
  state: 'State',
  city_state: 'City/State',
  postalCode: 'Postal Code',
  country: 'Country',
  phone: 'Work Phone',
  passportNumber: 'Passport Number',
  mobile: 'Mobile',
  includeCountryCode: 'Include country code',
  zip: 'Zip Code',
  dietaryRestriction: 'Dietary restrictions',
  jobTitle: 'Job Title',
  suffix: 'Suffix',
  suffixCollection: ['Ph.D.', 'M.D.', 'Esq.', 'Sr.', 'Jr.'],
  gender: 'Gender',
  genderOptions: ['Female', 'Male', 'Other'],
  joiningDinner: 'Specify dietary requirements',
  personalDetails: 'Personal Details',
  affiliations: 'Affiliations',
  addAffiliation: 'Add an affiliation',
  contactInfo: 'Contact Information',
  department: 'Department',
  institute: 'Institute/Hospital',
  streetAddress: 'Street Address',
  academicStatus: 'Academic Status',
  allowEmailContact: 'Please confirm we can include your email address on the next conference mailing list',
  acceptedTermsLabelParts: [
    'The personal information you provide is collected and processed by us in accordance with our ',
    'privacy policy',
    '. By ticking this box, you agree to our ',
    'terms and conditions',
    '.',
  ],
  fields: {
    flight: {
      arrival: 'Arrival',
      departure: 'Departure',
      flightDetails: 'Flight Details',
      date: 'Date',
      airline: 'Airline',
      flightNumber: 'Flight Number',
      arrivalTime: 'Arrival Time',
      departureTime: 'Departure Time',
    },
  },
  login: {
    password: 'Password',
    passwordConfirmation: 'Password confirmation',
    alreadyRegisteredQuestion: 'Already registered? Sign in here',
    alreadyRegisteredFact: 'Email already exists. Sign in here',
    registrationClosed: 'Registration is now closed',
    passwordHint: '10 characters minimum. Case sensitive.',
    mailTitle: (conferenceName: string) => 'Login to ' + conferenceName,
    toLoginAgain: 'To login again, please follow',
    clickHere: 'this link',
    wrongToken: 'Link expired or already used. Please try again.',
    loggingIn: 'Logging in...',
    newPassword: 'New Password',
    currentPassword: 'Current Password',
    changePassword: 'Change Password',
    choosePassword: 'Choose Password',
    mustMatch: 'Must match password',
    wrongPassword: 'Wrong Password',
    passwordChanged: 'Password Changed',
    forgotPassword: 'Forgot Password',
    oneTimeLoginLink: 'Get email with login link',
    toResetPassword: 'Forgot your password? You can',
    toResetPasswordLink: 'reset it here',
    success: (as: string) => 'Logged in as ' + as,
    logoutSuccess: 'Logged out successfully',
  },
  titles: {
    personalDetails: 'Personal Details',
    affiliations: 'Affiliation / Organization',
    contactDetails: 'Contact Details',
    registrationPricing: 'Registration Pricing',
    conferenceEvents: 'Conference Events',
    optionalTours: 'Optional Tours',
    transportationOptions: 'Transportation Options',
    flights: 'Flights',
    register: 'Register',
    extra: 'Extra',
    transports: 'Transfers',
  },
  abstracts: {
    submitAbstract: 'Submit Abstract',
    submitNewAbstract: 'Submit another abstract',
    noAbstractsYet: 'No abstracts yet',
    symposium: 'Symposium',
    submitToGroup: (groupLabel: string) => `Submit another abstract for ${groupLabel}`,
    addToGroup: (groupLabel: string) => `Add abstract for ${groupLabel}`,
    addAnotherToGroup: (groupLabel: string) => `Add another abstract for ${groupLabel}`,
    editGroup: (groupLabel: string) => `Edit ${groupLabel}`,
    submittingFor: (groupLabel: string) => `Submitting for ${groupLabel}`,
    newGroup: (groupLabel: string) => `New ${groupLabel}`,
    newGroupLabel: (groupLabel: string) => `${groupLabel} label`,
    newGroupDescription: (groupLabel: string) => `${groupLabel} description`,
    categoryClosed: (category: string) => `${category} (submission closed)`,
    missing: (error: string) => `Missing ${error}`,
    editClosed: 'Editing closed',
    title: 'Abstract Title',
    body: 'Abstract Body',
    addMessage: (role: string) => `Add ${role}`,
    removeMessage: (role: string) => `Remove ${role}`,
    author: 'Author',
    authors: 'Authors',
    requestedCategory: 'Abstract type',
    comment: 'Comment',
    edit: 'Edit',
    role: 'Role',
    submitterOrdinal: 'Order',
    submitterNote: 'Note',
    editSubmittedAbstracts: 'Edit submitted abstracts',
    submittedFor: `Submitted for:`,
    roles: {
      SUBMITTER: 'Submitting on behalf of the author',
      AUTHOR: 'I am a co-author',
      PRESENTING: 'I am an author and will be presenting this work',
    },
    authorsGuidance:
      'Please enter the affiliations of <b>all</b> authors as follows:<br>' +
      'Department,  Institution, City, Country<br>' +
      'e.g. <b>Department:</b> Department of Neuroscience, <b>Institution:</b> Johns Hopkins University<br>' +
      'School of Medicine, <b>City:</b> Baltimore, MD, <b>Country:</b> USA<br />',
    submitterRoleTitle: 'What is your role in this abstract?',
    submitterOrdinalTitle: 'Where in the order of authors would your name appear?',
    state: {
      [AbstractState.Accepted]: 'Accepted',
      [AbstractState.Archive]: 'Archived',
      [AbstractState.Assigned]: 'Assigned',
      [AbstractState.New]: 'New',
      [AbstractState.Pulled]: 'Pulled',
      [AbstractState.Rejected]: 'Rejected',
      [AbstractState.Scheduled]: 'Scheduled',
      [AbstractState.Withdrawn]: 'Withdrawn',
    },
  },
  actions: {
    addAffiliation: 'Add Affiliation',
    removeAffiliation: 'Remove Affiliation',
    noAffiliations: 'No Affiliations',
    submit: 'Submit',
    saved: 'Saved',
    next: 'Next',
    back: 'Back',
    preview: 'Preview',
    link: 'Link',
  },
  payments: {
    amount: 'Amount',
    check_account_number: 'Bank account number',
    check_bank_number: 'Bank number',
    check_branch_number: 'Bank branch number',
    check_number: 'Check number',
    check_owner: 'Check owner',
    reference_number: 'Reference number',
    sponsor_name: 'Sponsor name',
    sponsor_details: 'Sponsor details',
    last_four_digits: 'Last four digits of card',
    invoice_to: 'Invoice to',
    invoice_number: 'Invoice number',
    invoice_details: 'Contact Details',
    invoice_po: 'PO Box',
    total: 'Total',
    paymentMethod: 'Payment Method',
    types: {
      CREDIT_GUARD: 'Credit Card',
      CREDIT_GUARD_CREDIT: 'Credit Card Credit',
      CHECK: 'Check',
      CASH: 'Cash',
      INVOICE: 'Invoice',
      WIRE: 'Bank Transfer',
      SPONSOR: 'Sponsor',
    },
    productTypes: {
      [ProductType.Attendance]: 'Registration',
      [ProductType.Hotel]: 'Accommodations',
      [ProductType.HotelNight]: 'Accommodations',
      [ProductType.Transport]: 'Transports',
      [ProductType.ConferenceEvent]: 'Events',
      [ProductType.TourEvent]: 'Tours',
      [ProductType.Flight]: 'Flight',
      [ProductType.Other]: 'Other',
      [ProductType.Partner]: 'Accompanying',
      [ProductType.AttendanceDay]: 'Registration',
      [ProductType.Extra]: 'Other',
      [ProductType.Modifier]: 'Extra',
    },
    invoiceTypes: {
      [InvoiceType.InvoiceReceipt]: 'Invoice/Receipt',
      [InvoiceType.Invoice]: 'Invoice',
      [InvoiceType.Receipt]: 'Receipt',
      [InvoiceType.ReverseInvoice]: 'Credit Invoice',
    } as Record<InvoiceType, string>,
    amountDue: (amount: string) => `Total ${amount} Due`,
    paid: (amount: string) => `${amount} Paid`,
    title: 'Payment',
    cancelMessage: 'Payment cancelled. Please try again.',
    errorMessage: 'Error in payment. Please try again later.',
  },
  hotels: {
    checkIn: 'Check In',
    checkOut: 'Check Out',
    date: 'Date',
    nights: (v: number) => v + (v > 1 ? ' Nights' : ' Night'),
    hotelAndCategory: 'Hotel and Room Category',
    SINGLE: 'Single Room',
    DOUBLE: 'Double Room',
    TRIPLE: 'Triple Room',
    QUAD: 'Quadruple Room',
    'DOUBLE:SHARED': 'Double Room Shared',
    'TRIPLE:SHARED': 'Triple Room Shared',
    'QUAD:SHARED': 'Quadruple Room Shared',
    sharing: {
      SINGLE: 'Single Room',
      DOUBLE: 'Person in Double Room',
      TRIPLE: 'Person in Triple Room',
      QUAD: 'Person in Quadruple Room',
    },
    ratesArePer: 'Rates are per room, per night',
    accommodation: 'Accommodation',
    withHotel: 'I/we require accommodation',
    singleExtra: (price: string) => `Single Room Supplement (${price})`,
    shareExtra: 'I will share a room with',
    shareWith: 'Share room with',
    sharingWith: 'Sharing room with',
    extraPrice: (v: string) => v + ' extra charge',
    shareAssign: 'Please assign me a roommate',
    willShareType: {
      FULL: 'Pay for the entire room cost',
      PARTIAL: 'Pay for my portion of the room cost',
    },
    shareWithAccompanyingPerson: 'Share with accompanying person',
    shareWithAnotherParticipant: 'Share with another participant',
    soldOut: 'Sold Out',
    extras: 'Extras and upgrades',
  },
  ui: {
    error: 'Sorry, something went wrong. Please try again later.',
    authError: 'Permission denied',
    radio: {
      name: 'Name',
      description: 'Description',
      price: 'Price',
      date: 'Date/s',
    },
    defaultProps: {
      pleaseSelect: 'Please select',
      pleaseConfirm: 'Please confirm',
      option: (i: number) => `Option ${i}`,
      pleaseEnterText: 'Please enter text',
    },
    forms: {
      confirmLeave: 'Form contains unsaved data. Are you sure you want to navigate away?',
      defaultAddMessage: 'Add another',
      defaultRemove: 'Remove',
      defaultNoItems: 'No Items',
    },
    validations: {
      required: 'Required',
      wordsOrLess: (num: number) => `${num} words or less`,
      charsAtLeast: (num: number) => `At least ${num} characters`,
      charsOrLess: (num: number) => `${num} characters or less`,
      minimum: (num: number) => `Must be at least ${num}`,
      hasMinimumLength: (num: number) => `Must be at least ${num}`,
      email: 'Invalid email format',
      onlyHebrew: 'Hebrew only',
      onlyEnglish: 'English only',
    },
    login: {
      email: 'Email',
      password: 'Password',
      registrationNumber: 'Registration Number',
      registrationNumberHint: 'Appears in the bottom of the registration email',
      wrong: 'Wrong email or password',
      login: 'Login',
      logout: 'Logout',
      pleaseCheckEmail: 'Please check your email for a login link.',
      loggedInAs: (name: string) => 'Signed in as ' + name,
      backHome: 'Back home',
    },

    upload: {
      upload: 'Select File',
      preparing_upload: 'Preparing upload',
      uploading: 'Uploading file...',
      complete: 'Upload complete',
      error: 'Upload error',
      uploadedFile: 'Uploaded File',
    },
  },
  emails: {
    registration: {
      registration_summary: 'Registration Summary',
      registration_summary_no_reg: 'Summary',
      headers: {
        [ProductType.ConferenceEvent]: 'Conference Events',
        [ProductType.TourEvent]: 'Optional Tours',
        [ProductType.Transport]: 'Transportation Options',
        [ProductType.Flight]: 'Flights',
        [ProductType.Attendance]: 'Registration',
        [ProductType.AttendanceDay]: 'Day Registration',
        [ProductType.Hotel]: 'Accommodation',
        [ProductType.HotelNight]: 'Day Accommodation',
        [ProductType.Other]: 'Other',
        [ProductType.Partner]: 'Accompanying',
        [ProductType.Extra]: 'Extra',
        [ProductType.Modifier]: 'Extra',
      },
      number_of_people: 'Number of people',
      person: 'person',
    },
  },
  home: {
    titles: {
      register: 'Register',
      review: 'Review',
      super_reviewer: 'All Abstracts',
      abstracts: 'Abstracts',
      pay: 'Pay',

      sci_title_closed: 'Abstract submission is now closed',
      sci_title_track: 'You can still track the status of your submitted work.',
      review_title_open:
        'You are a scientific abstract reviewer for this conference. Click here to review the abstracts that have been assigned to you.',
      review_title_closed: 'Scientific abstract review for this conference is complete.',
      review_title_not_open: (openDate: string) =>
        `You are a scientific abstract reviewer for this conference. Abstract submission will begin ${openDate}`,
      sci_title_open: 'Submit abstracts for the conference',
      sci_title_open_has_abstracts: 'Submit abstracts for the conference or edit submitted abstracts',
      sci_title_not_open: (openDate: string) => `Abstract submission will begin ${openDate}`,
      register_title_open: 'Registration is open',
      register_title_not_open: (openDate: string) => `Registration for will begin on ${openDate}`,
      register_title_closed: 'Registration is closed',
      register_you_have_complete: 'You have completed the registration process.',
      register_incomplete:
        'Your registration is still incomplete. To guarantee your participation, please complete your payment information',
      super_reviewer_title: 'You have access to all submitted abstracts',
    },
  },
  registrations: {
    complete: '(Complete)',
    closed: '(Registration closed)',
  },
  science: {
    roles: {
      oral: 'Oral',
      poster: 'Poster',
      presenting: 'Presenting',
      author: 'Author',
    },
    ui: {
      abstractToReview: 'Abstracts To Review',
      allAbstracts: 'All Abstracts',
      noAbstractsAssignedYet: 'No abstracts assigned yet',
      reviewAbstract: 'Review Abstract',
      abstractNumber: (num: number) => 'Abstract ' + num,
      yourReview: 'Your Review',
      category: (index: number) => 'Category ' + index,
      passFail: 'Pass / Fail',
      pass: 'Pass',
      fail: 'Fail',
      note: 'Reviewer Note',
      attachment: 'Attachment',
      done: 'Review Complete',
      submittingFor: (groupLabel: string, groupTitle: string) =>
        `Submitting for ${groupTitle}: ${groupLabel}`,
    },
  },
  registration: {
    categories: {
      early: 'Early',
      regular: 'Regular',
      onsite: 'Onsite',
    },
    hasAccompanying: 'I would like to register my Accompanying Person',
    participantName: 'Tour Participant Name (Can be changed)',
    participantNumberName: (i: number) => `Participant ${i} Name`,
    name: 'Accompanying person name',
    person: 'Person',
    accompanyingPerson: 'Accompanying person',
    eventParticipants: `Participants:`,
    extraPersonDetails: (label: string, i: number, total: number) =>
      (total > 1 ? `${label} ${i}` : label) + ' details',
    cancelPolicy: 'Cancellation Policy',
    pleaseLogin: 'Please login to continue',
  },

  complete: {
    thankYou: 'Thank you for registering.',
    emailSent: 'An email will be sent to the address you provided with further details.',
    extraQueries: 'If you have any queries, please contact us at:',
    tel: 'Tel:',
    fax: 'Fax:',
  },
  abstractComplete: {
    thankYou: 'Thank you for submitting an abstract',
    ifYouLikeAdditional: 'If you would like to submit an additional abstract please',
    pleaseSendTheOther:
      'Please send the other presenters this link to allow them to submit their abstract for this symposium',
    toEditSubmitted: 'To edit submitted abstracts',
    clickHere: 'click here',
  },
  target: 'Target Conferences Ltd.',
}).supporting('he', {
  andList: (values: string[]) => andJoin(values, ' ו'),
  salutation: 'תואר',
  salutationsOptions: ["פרופ'", 'ד"ר', 'מר', "גב'"],
  firstName: 'שם פרטי',
  middleName: 'שם אמצעי',
  lastName: 'שם משפחה',
  email: 'דואר אלקטרוני',
  address1: 'כתובת למשלוח דואר',
  address2: 'כתובת מקום העבודה',
  city: 'עיר',
  state: 'State',
  city_state: 'עיר',
  postalCode: 'מיקוד',
  country: 'מדינה',
  phone: 'טלפון בעבודה',
  passportNumber: 'מספר דרכון',
  mobile: 'טלפון נייד',
  includeCountryCode: 'כולל קידומת מדינה',
  zip: 'מיקוד',
  dietaryRestriction: 'מגבלות תזונתיות',
  jobTitle: 'תפקיד',
  suffix: 'תואר',
  suffixCollection: [],
  gender: 'מין',
  genderOptions: ['זכר', 'נקבה'],
  joiningDinner: 'מלא/י אם בכוונתך להצטרף לארוחת הערב',
  personalDetails: 'פרטים אישיים',
  affiliations: 'שיוך',
  addAffiliation: 'הוסף שיוך',
  contactInfo: 'פרטי התקשרות',
  streetAddress: 'כתובת',
  academicStatus: 'סטטוס אקדמאי',
  department: 'מחלקה',
  institute: 'מוסד/חברה',
  allowEmailContact: 'אני מאשר/ת לשלוח לי חומר פרסומי בנוגע לכנסים עתידיים',
  acceptedTermsLabelParts: ['הבנתי ואני מאשר/ת את ', 'מדיניות הפרטיות ', 'ואת ', 'תנאי השימוש', '.'],
  fields: {
    flight: {
      arrival: 'הלוך',
      departure: 'חזור',
      flightDetails: 'פרטי טיסה',
      date: 'תאריך',
      airline: 'חברת תעופה',
      flightNumber: 'מספר טיסה',
      arrivalTime: 'שעת נחיתה',
      departureTime: 'שעת המראה',
    },
  },
  login: {
    password: 'סיסמה',
    passwordConfirmation: 'אימות סיסמה',
    alreadyRegisteredQuestion: 'נרשמתם בעבר? לחצו כאן לכניסה',
    alreadyRegisteredFact: 'נרשמתם בעבר, לחצו כאן לכניסה',
    registrationClosed: 'ההרשמה סגורה',
    passwordHint: '10 תווים מינימום',
    mailTitle: (conferenceName: string) => 'התחברות אל  ' + conferenceName,
    toLoginAgain: 'על מנת להתחבר שוב אנא לחצ/י',
    clickHere: 'כאן',
    wrongToken: 'הלינק פג תוקף או משומש. אנא נסו שוב',
    loggingIn: 'מתחברים...',
    newPassword: 'סיסמה חדשה',
    currentPassword: 'סיסמה נוכחית',
    changePassword: 'שינוי סיסמה',
    choosePassword: 'בחירת סיסמה',
    mustMatch: 'חייב להתאים לסיסמה',
    wrongPassword: 'סיסמה שגויה',
    passwordChanged: 'סיסמה שונתה',
    forgotPassword: 'שכחתי סיסמה',
    oneTimeLoginLink: 'אימייל עם לינק כניסה חד פעמי',
    toResetPassword: 'שכחתם את הסיסמה?',
    toResetPasswordLink: 'לחצו פה לשנות אותה',
    success: (as: string) => 'התחברתם בתור ' + as,
    logoutSuccess: 'התנתקתם מהמערכת בהצלחה',
  },
  titles: {
    personalDetails: 'פרטים אישיים',
    affiliations: 'שיוך',
    contactDetails: 'פרטי התקשרות',
    registrationPricing: 'מחירי הרשמה',
    conferenceEvents: 'אירועי הכנס',
    optionalTours: 'טיולים לבחירה',
    transportationOptions: 'אפשרויות הסעה',
    flights: 'טיסות',
    register: 'הרשמה',
    extra: 'תוספות',
    transports: 'הסעות',
  },
  abstracts: {
    submitAbstract: 'שליחת תקציר',
    submitNewAbstract: 'שליחת תקציר נוסף',
    noAbstractsYet: 'אין תקצירים עדיין',
    symposium: 'סימפוזיון',
    submitToGroup: (groupLabel: string) => `שליחת תקציר נוסף עבור${groupLabel}`,
    addToGroup: (groupLabel: string) => `שליחת תקציר עבור${groupLabel}`,
    addAnotherToGroup: (groupLabel: string) => `שליחת תקציר נוסף עבור${groupLabel}`,
    editGroup: (groupLabel: string) => `עריכת  ${groupLabel}`,
    submittingFor: (groupLabel: string) => `שליחה עבור ${groupLabel}`,
    newGroup: (groupLabel: string) => `${groupLabel} חדש`,
    newGroupLabel: (groupLabel: string) => `כותרת ${groupLabel}`,
    newGroupDescription: (groupLabel: string) => `תיאור ${groupLabel}`,
    categoryClosed: (category: string) => `${category} (סגור)`,
    missing: (error: string) => `מסר ${error}`,
    editClosed: 'עריכה סגורה',
    title: 'כותרת התקציר',
    body: 'גוף התקציר',
    addMessage: (role: string) => `הוספת ${role}`,
    removeMessage: (role: string) => `הסרת ${role}`,
    author: 'מחבר/ת',
    authors: 'מחברים/ות',
    requestedCategory: 'סוג תקציר',
    comment: 'הערה',
    edit: 'עריכה',
    role: 'תפקיד',
    submitterOrdinal: 'סדר',
    submitterNote: 'הערה',
    editSubmittedAbstracts: 'עריכת תקצירים',
    submittedFor: `נשלח עבור`,
    roles: {
      SUBMITTER: 'הגשת תקציר בשם המחבר/ת',
      AUTHOR: 'אני מחבר',
      PRESENTING: 'אני המחבר/ת הראשי/ת ואני אציג את המחקר',
    },
    authorsGuidance:
      '" ציין את השיוכים של <b>כל</b> המחברים כך:<br>\n' +
      '      (מחלקה, מוסד, עיר, מדינה)<br>\n' +
      '      לדוגמה <b>מחלקה:</b> המחלקה לנוירוכירורגיה, <b>מוסד:</b> המרכז רפואי רבין, <br> <b>עיר:</b> פתח תקווה <b>מדינה:</b> ישראל<br>"',
    submitterRoleTitle: 'מה חלקך בתקציר?',
    submitterOrdinalTitle: 'היכן את/ה רוצה להופיע ברשימת הכותבים?',
    state: {
      [AbstractState.Accepted]: 'התקבל',
      [AbstractState.Archive]: 'ארכיון',
      [AbstractState.Assigned]: 'שובץ לביקורת',
      [AbstractState.New]: 'חדש',
      [AbstractState.Pulled]: 'נמשך',
      [AbstractState.Rejected]: 'נדחה',
      [AbstractState.Scheduled]: 'שובץ',
      [AbstractState.Withdrawn]: 'נמשך',
    },
  },
  actions: {
    addAffiliation: 'הוסף שיוך',
    removeAffiliation: 'הסר שיוך',
    noAffiliations: 'אין שיוך',
    submit: 'שליחה',
    saved: 'נשמר',
    next: 'הבא',
    back: 'קודם',
    preview: 'תצוגה תקדימה',
    link: 'קישור',
  },
  payments: {
    amount: 'סכום',
    check_account_number: 'מספר חשבון בנק',
    check_bank_number: 'מזהה בנק',
    check_branch_number: 'מספר סניף',
    reference_number: 'אסמכתה',
    check_number: 'מספר שיק',
    check_owner: 'השם המופיע על השיק',
    sponsor_name: 'שם הגוף/גורם מממן',
    sponsor_details: 'פרטי הגוף/גורם המממן',
    last_four_digits: 'ארבע ספרות אחרונות ',
    invoice_to: 'חשבונית עבור',
    invoice_number: 'מספר חשבונית',
    invoice_details: 'פרטי התקשרות',
    invoice_po: 'תא דואר',
    total: 'סך הכל',
    paymentMethod: 'שיטת תשלום',
    types: {
      CREDIT_GUARD: 'כרטיס אשראי מאובטח',
      CREDIT_GUARD_CREDIT: 'כרטיס אשראי מאובטח (זיכוי)',
      CHECK: 'שיק',
      WIRE: 'העברה בנקאית',
      SPONSOR: 'הרשמה מסובסדת',
      INVOICE: 'חשבונית',
      CASH: 'מזומן',
    },
    title: 'תשלום',
    amountDue: (amount: string) => `סך הכל לתשלום: ${amount}`,
    paid: (amount: string) => `שולם ${amount}`,
    cancelMessage: 'התשלום בוטל. אנא נסו שוב.',
    errorMessage: 'שגיאה בתשלום. אנא נסו שוב מאוחר יותר.',
    productTypes: {
      [ProductType.Attendance]: 'הרשמה',
      [ProductType.Hotel]: 'לינה',
      [ProductType.HotelNight]: 'לינה',
      [ProductType.Transport]: 'הסעות',
      [ProductType.ConferenceEvent]: 'מרכיבים אופציונליים',
      [ProductType.TourEvent]: 'מרכיבים אופציונליים',
      [ProductType.Flight]: 'טיסה',
      [ProductType.Other]: 'אחר',
      [ProductType.Partner]: 'נלווה',
      [ProductType.AttendanceDay]: 'הרשמה',
      [ProductType.Extra]: 'תוספת',
      [ProductType.Modifier]: 'תוספת',
    },
    invoiceTypes: {
      [InvoiceType.InvoiceReceipt]: 'חשבונית מס קבלה',
      [InvoiceType.Invoice]: 'חשבונית מס',
      [InvoiceType.Receipt]: 'קבלה',
      [InvoiceType.ReverseInvoice]: 'חשבונית זיכוי',
    } as Record<InvoiceType, string>,
  },
  hotels: {
    checkIn: 'לינה מתאריך',
    checkOut: 'עד תאריך',
    date: 'תאריך',
    nights: (v: number) => (v > 1 ? [` לילות `, v].reverse().join(' ') : 'לילה אחד'),
    hotelAndCategory: 'שם המלון וסוג החדר',
    SINGLE: 'חדר ליחיד',
    DOUBLE: 'חדר זוגי',
    TRIPLE: 'חדר לשלושה',
    QUAD: 'חדר לארבעה',
    'DOUBLE:SHARED': 'חדר זוגי עם שותפ/ה',
    'TRIPLE:SHARED': 'חדר לשלושה עם שותפים',
    'QUAD:SHARED': 'חדר לארבעה עם שותפים',
    sharing: {
      SINGLE: 'אדם בחדר יחיד',
      DOUBLE: 'אדם בחדר זוגי',
      TRIPLE: 'אדם בחדר לשלושה',
      QUAD: 'אדם בחדר לארבעה',
    },
    ratesArePer: 'המחירים הם לחדר, ללילה',
    accommodation: 'לינה',
    withHotel: 'אני/אנו מעוניינים להזמין לינה',
    singleExtra: (price: string) => 'חדר ליחיד (' + price + ' תוספת)',
    shareExtra: 'ברצוני לחלוק חדר עם',
    extraPrice: (v: string) => v + ' תוספת',
    shareAssign: 'אנא בחרו לי שותף לחדר',
    shareWith: 'אחלוק עם',
    sharingWith: 'חולק/ת עם',
    willShareType: {
      FULL: 'אשלם את מלוא התשלום על החדר',
      PARTIAL: 'אשלם על חלקי היחסי בחדר',
    },
    shareWithAccompanyingPerson: 'אחלוק עם מלווה',
    shareWithAnotherParticipant: 'אחלוק עם משתתף אחר',
    soldOut: 'אזל המלאי',
    extras: 'תוספות ושדרוגים',
  },
  ui: {
    error: 'משהו השתבש, אנא נסו שנית מאוחר יותר.',
    authError: 'אין גישה',
    radio: {
      name: 'שם',
      description: 'תיאור',
      price: 'מחיר',
      date: 'תאריך/ים',
    },
    defaultProps: {
      pleaseSelect: 'אנא בחרו',
      pleaseConfirm: 'אנא אשרו',
      option: (i: number) => `אפשרות ${i}`,
      pleaseEnterText: 'אנא מלאו',
    },
    forms: {
      confirmLeave: 'הטופס לא נשמר עדין. האם ברצונך לנווט לעמוד אחר?',
      defaultAddMessage: 'הוספה',
      defaultRemove: 'הסרה',
      defaultNoItems: 'אין פריטים',
    },
    validations: {
      required: 'חובה',
      wordsOrLess: (num: number) => num + ' מילים או פחות',
      charsAtLeast: (num: number) => `לפחות ${num} אותיות`,
      charsOrLess: (num: number) => `${num} אותיות או פחות`,
      minimum: (num: number) => `מינימום ${num}`,
      hasMinimumLength: (num: number) => `מינימום ${num}`,
      email: 'אימייל שגוי',
      onlyHebrew: 'עברית בלבד',
      onlyEnglish: 'אנגלית בלבד',
    },
    login: {
      email: 'כתובת מייל',
      password: 'סיסמה',
      registrationNumber: 'מספר הרשמה',
      registrationNumberHint: 'מופיע בתחתית אימייל ההרשמה',
      wrong: 'כתובת אימייל או סיסמה שגויים',
      login: 'כניסה למערכת',
      logout: 'יציאה',
      pleaseCheckEmail: 'קישור לכניסה למערכת נשלח לכתובת הדוא"ל',
      loggedInAs: (name: string) => 'מחובר בתור ' + name,
      backHome: 'חזרה לעמוד הבית',
    },
    upload: {
      upload: 'העלאה',
      preparing_upload: 'Preparing upload',
      uploading: 'Uploading file...',
      complete: 'Upload complete',
      error: 'Upload error',
      uploadedFile: 'Uploaded File',
    },
  },
  emails: {
    registration: {
      headers: {
        [ProductType.ConferenceEvent]: 'אירועי הכנס',
        [ProductType.TourEvent]: 'טיולים לבחירה',
        [ProductType.Transport]: 'אפשרויות הסעה',
        [ProductType.Flight]: 'טיסות',
        [ProductType.Attendance]: 'קטגורית הרשמה',
        [ProductType.AttendanceDay]: 'הרשמה יומית',
        [ProductType.Hotel]: 'לינה',
        [ProductType.HotelNight]: 'לינה',
        [ProductType.Other]: 'לינה',
        [ProductType.Partner]: 'נלווה',
        [ProductType.Extra]: 'תוספת',
        [ProductType.Modifier]: 'תוספת',
      },
      registration_summary: 'פרטי ההרשמה',
      registration_summary_no_reg: 'פרטים',
      number_of_people: 'מספר משתתפים',
      person: 'משתתפ/ים',
    },
  },
  home: {
    titles: {
      register: 'הרשמה',
      review: 'סקירה',
      abstracts: 'תקצירים',
      pay: 'תשלום',
      super_reviewer: 'כל התקצירים',

      sci_title_closed: 'הגשת התקצירים הסתיימה',
      sci_title_track: 'הכנס/י כדי לעקוב אחר מצב התקצירים שהגשת',
      review_title_open: 'נבחרת לסקור מדעית את תקצירי הכנס הנ"ל. לחץ/י כאן לסקירה של התקצירים שנתבקשת לסקור.',
      review_title_closed: 'סקירת התקצירים הסתיימה.',
      review_title_not_open: (openDate: string) =>
        ` נבחרת לסקור מדעית את תקצירי הכנס הנ"ל. הגשת תקצירים תפתח בתאריך ${openDate}`,
      sci_title_open: 'הגש/י תקציר לכנס',
      sci_title_open_has_abstracts: 'להגשת תקצירים או עריכת תקצירים שהוגשו',
      sci_title_not_open: (openDate: string) => ` הגשת תקצירים תפתח בתאריך ${openDate}`,
      register_title_open: 'ההרשמה פתוחה',
      register_title_not_open: (openDate: string) => `ההרשמה תפתח בתאריך${openDate}`,
      register_title_closed: 'ההרשמה סגורה',
      register_you_have_complete: 'השלמת את תהליך ההרשמה.',
      register_incomplete: `הרישום לא הושלם. על מנת להבטיח את השתתפותך והשלמ/י את תהליך הרישום על ידי הזנת פרטי התשלום.`,
      super_reviewer_title: 'לכל התקצירים בכנס',
    },
  },
  registrations: {
    complete: '(הושלם)',
    closed: '(ההרשמה סגורה)',
  },
  science: {
    roles: {
      oral: 'הרצאה',
      poster: 'פוסטר',
      presenting: 'מציג/ה',
      author: 'מחברת',
    },
    ui: {
      abstractToReview: 'תקצירים לסקירה',
      allAbstracts: 'כל התקצירים',
      noAbstractsAssignedYet: 'אין עדיין תקצירים',
      reviewAbstract: 'סקירת תקציר',
      abstractNumber: (num: number) => 'תקציר ' + num,
      yourReview: 'הסקירה שלך',
      category: (index: number) => 'קטגוריה ' + index,
      passFail: 'עובר / לא עובר',
      pass: 'עובר',
      fail: 'לא עובר',
      note: 'הערת הסוקר',
      attachment: 'קובץ מצורף',
      done: 'נסקר',
      submittingFor: (groupLabel: string, groupTitle: string) => `מוגש עבור ${groupTitle}: ${groupLabel}`,
    },
  },
  registration: {
    categories: {
      early: 'מוקדמת',
      regular: 'רגילה',
      onsite: 'במקום',
    },
    hasAccompanying: 'ברצוני לרשום את הנלווה שלי',
    participantName: 'שם המשתתף בטיול (ניתן לשינוי)',
    participantNumberName: (i: number) => `משתתף מספר ${i}`,
    name: 'שם הנלווה',
    person: 'משתתף',
    accompanyingPerson: 'נלווה',
    eventParticipants: `משתתפים`,
    extraPersonDetails: (label: string, i: number, total: number) =>
      'פרטי ' + (total > 1 ? `${label} ${i}` : label),
    cancelPolicy: 'מדיניות ביטול',
    pleaseLogin: 'אנא התחברו למערכת על מנת להמשיך',
  },
  complete: {
    thankYou: 'תודה על הרשמתך',
    emailSent: 'הודעה על קליטת פרטי ההרשמה במערכת נשלחה לכתובת הדוא"ל שסיפקת.',
    extraQueries: 'נשמח לסייע בכל שאלה',
    tel: 'טל:',
    fax: 'פקס:',
  },
  abstractComplete: {
    thankYou: 'אנחנו מודים לך על הגשת ההרצאה',
    ifYouLikeAdditional: 'להגשת הרצאה נוספת',
    toEditSubmitted: 'לעריכת הרצאה קיימת',
    clickHere: 'לחצו כאן',
    pleaseSendTheOther:
      'אנא שלחו את הקישור הבא למציגים האחרים את הקישור הבא על מנת שיוכלו להגיש תקצירים לסימפוזיון זה',
  },
  target: 'טרגט כנסים בע"מ',
})

export type FieldTranslations = typeof CommonFieldTranslations extends Translator<infer T> ? T : never

export function getCommonTranslations(language?: Language) {
  return CommonFieldTranslations.messagesFor(languageTag(language?.toLowerCase() || 'en'))
}
