import { useMemo, useState } from 'react'
import { CommonFieldTranslations } from '../../data/CommonFieldTranslations'
import { createContainer } from '../common/unstated-next'
import { languageTag } from '../../../vendor/typed-intl'

const useTranslationContext = (initial?: string) => {
  const [locale, setLocale] = useState(initial)
  const initialLowercase = initial?.toLowerCase() || 'en'

  const messages = useMemo(
    () => CommonFieldTranslations.messagesFor(languageTag(initialLowercase)),
    [initialLowercase]
  )

  return {
    locale: initial,
    setLocale,
    messages,
  }
}

export const TranslationContext = createContainer(useTranslationContext)

export const useTranslations = () => {
  const { messages } = TranslationContext.useContainer()
  return messages
}

export type TranslationMessages = ReturnType<typeof CommonFieldTranslations['messages']>
