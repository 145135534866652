import { TranslationContext } from '../translations/Translations'
import { CommonFieldTranslations, FieldTranslations } from '../../data/CommonFieldTranslations'
import { languageTag } from '../../../vendor/typed-intl/LanguageTag'

export const useCommonTranslations = (): FieldTranslations => {
  return (
    (TranslationContext.useContainer().messages as FieldTranslations) ||
    CommonFieldTranslations.messagesFor(languageTag('en'))
  )
}
