import React from 'react'
import { Button, Snackbar } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import { SnackbarProviderProps } from 'material-ui-snackbar-provider/lib/SnackbarProvider'

export const CustomSnackbar: SnackbarProviderProps['SnackbarComponent'] = ({
  message,
  action,
  ButtonProps,
  SnackbarProps,
  customParameters,
}) =>
  //   message: string
  //   action: string
  //   ButtonProps: ButtonProps
  //   SnackbarProps: SnackbarProps
  //   customParameters: {
  //     type: Color
  //   }
  // })
  {
    return (
      <Snackbar autoHideDuration={3000} {...SnackbarProps}>
        <Alert
          severity={customParameters?.type}
          action={
            action != null && (
              <Button color="inherit" size="small" {...ButtonProps}>
                {action}
              </Button>
            )
          }
        >
          {message}
        </Alert>
      </Snackbar>
    )
  }
