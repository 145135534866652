import React from 'react'

type ValueType<Value> = Value | Partial<Value>

export interface ContainerProviderProps<State = void> {
  initialState?: State
  children: React.ReactNode
}

export interface Container<Value, State = void> {
  Provider: React.ComponentType<ContainerProviderProps<State>>
  useContainer: () => ValueType<Value>
}

export function createContainer<Value, State = Value>(
  useHook: (initialState?: State) => Value
): Container<Value, State> {
  const Context = React.createContext<Value | null>(null)

  function Provider(props: ContainerProviderProps<State>) {
    const value = useHook(props.initialState)
    return <Context.Provider value={value}>{props.children}</Context.Provider>
  }

  function useContainer(): ValueType<Value> {
    return React.useContext(Context) || {}
  }

  return { Provider, useContainer }
}

export function useContainer<Value, State = void>(container: Container<Value, State>): ValueType<Value> {
  return container.useContainer()
}

