import App, { AppContext as NextAppContext, AppProps } from 'next/app'
import React, { useEffect, useMemo, useState } from 'react'
import Head from 'next/head'
import { jssPreset, StylesProvider, ThemeProvider } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import { APP_THEME } from '../src/theme'
import { AppContext } from '../src/components/layout/AppContext'
import { create } from 'jss'
import rtl from 'jss-rtl'
import { Router, useRouter } from 'next/router'
import type { Request } from 'express'
import type { ApolloClient } from '@apollo/client/core'
import { LayoutType } from '../src/components/common/LayoutType'
import { initializeApollo } from '../src/apollo/apollo_client'
import { AuthenticationError } from 'apollo-server-errors'
import { Loading } from '../src/components/common/Loading'
import dynamic from 'next/dynamic'
import { AppWrapper } from '../src/components/appWrapper'
import { isSessionAdmin } from '../src/permissions/isSessionAdmin'
import { isSsrMode } from '../src/consts'

const UserLayout = dynamic(() => import('../src/components/layout/UserLayout'), {
  loading: () => <Loading />,
})

const AdminLayout = dynamic(() => import('../src/components/layout/AdminLayout'), {
  loading: () => <Loading />,
})

if (typeof window !== 'undefined') {
  require('get-root-node-polyfill/implement')
  require('element-closest-polyfill')
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  require('globalthis/shim')()
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  require('object.fromentries/shim')()
}

function MyApp({
  Component,
  pageProps,
  router,
  req,
  apolloClient: apolloClientIn,
  apolloState,
  serverError,
}: AppProps & {
  apolloClient?: ApolloClient<any>
  apolloState: any
  Component: React.FC & { layoutType?: LayoutType }
  req?: Request
  serverError?: Error
}) {
  React.useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side')
    jssStyles?.parentElement?.removeChild(jssStyles)
  }, [])
  const jsRouter = useRouter()
  const [error, setError] = useState<Error | undefined>(serverError)

  const apolloClient = useMemo(
    () => apolloClientIn || initializeApollo(apolloState, isSsrMode() ? req : undefined, setError),
    [apolloClientIn, apolloState, req]
  )

  useEffect(() => {
    const listener = (e: any, options?: { shallow?: boolean }) => {
      setError(undefined)
      if (!options?.shallow) {
        apolloClient?.reFetchObservableQueries()
      }
    }
    Router.events.on('routeChangeComplete', listener)
    // window.addEventListener('')
    return () => Router.events.off('routeChangeComplete', listener)
  }, [apolloClient, setError])

  const { layoutType } = Component
  const adminPath = jsRouter.asPath?.startsWith('/admin')
  const Wrapper =
    layoutType === LayoutType.USER
      ? UserLayout
      : layoutType === LayoutType.REALLY_NONE || jsRouter.asPath === '/'
      ? React.Fragment
      : adminPath
      ? AdminLayout
      : UserLayout
  const query = useMemo(() => ({ ...router.query, ...jsRouter.query }), [jsRouter.query, router.query])

  const jss = useMemo(() => create({ plugins: [...jssPreset().plugins, rtl()] }), [])

  return (
    <React.Fragment>
      <Head>
        <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
        {/* PWA primary color */}
        <meta name="theme-color" content={APP_THEME.palette.primary.main} />
        <title>Target Conferences</title>
      </Head>

      <StylesProvider jss={jss}>
        <AppWrapper apolloClient={apolloClient}>
          <ThemeProvider theme={APP_THEME}>
            <CssBaseline />
            <AppContext.Provider initialState={{ query, error, setError }}>
              <Wrapper {...(layoutType === LayoutType.NONE ? { noLayout: true } : {})}>
                <Component {...pageProps} />
              </Wrapper>
            </AppContext.Provider>
          </ThemeProvider>
        </AppWrapper>
      </StylesProvider>
    </React.Fragment>
  )
}

MyApp.getInitialProps = async (appContext: NextAppContext) => {
  const {
    Component,
    router,
    ctx: { req: inReq, AppTree, err, res, asPath },
  } = appContext
  const req = inReq as Request
  const appProps = await App.getInitialProps(appContext)

  if (typeof window === 'undefined') {
    let insideError: Error | undefined
    if (req.path?.startsWith('/admin')) {
      const sessionData = await req.sessionData()
      insideError =
        !sessionData || !isSessionAdmin(sessionData) ? new AuthenticationError('Unauthenticated') : undefined
    }
    return {
      ...appProps,
      req: { session: req.session },
      serverError: insideError,
    }
  } else {
    return appProps
  }
}

export default MyApp
