import { red } from '@material-ui/core/colors'
import { createTheme, lighten, ThemeOptions } from '@material-ui/core/styles'

export const APP_THEME_OPTIONS = (variant?: 'filled' | 'outlined' | 'standard'): ThemeOptions => ({
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  palette: {
    primary: {
      main: '#7986cb',
    },
    secondary: {
      main: '#ffb74d',
      // main: pink['400'],
    },
    error: {
      main: red.A400,
    },
    // contrastThreshold: 1

    // tonalOffset: 0.3
  },
  props: {
    MuiSelect: {
      variant,
      inputProps: {},
    },
    MuiTextField: {
      variant,
      margin: 'normal',

      fullWidth: false,
    },
    MuiFormControl: {
      variant,
      margin: 'normal',
    },
    MuiButton: {
      variant: 'contained',
    },
  },
  overrides: {
    MuiButtonBase: {
      root: {
        '&.MuiToggleButton-root': {
          '&.Mui-selected': {
            background: 'hotpink',
          },
        },
      },
    },
    // @ts-ignore
    MuiAutocomplete: {
      inputRoot: {
        minWidth: '100px',
      },
      fullWidth: {
        '& $inputRoot': {
          width: '100%',
        },
      },
    },
    MuiTypography: {
      h5: {
        marginTop: '16px',
        marginBottom: '8px',
      },
    },
    MuiCardHeader: {
      title: {
        margin: 0,
      },
      action: {
        marginTop: '-4px',
        marginBottom: '-4px',
      },
    },
    MuiFormLabel: {
      root: {
        marginBottom: '0.1em',
        lineHeight: '1.3',
      },
    },
    MuiFormControlLabel: {
      label: {
        margin: '0.3em 0',
      },
    },
    MuiContainer: {
      root: {
        '@media print': {
          padding: 0,
        },
      },
    },
    MuiTabs: {
      root: {
        '&.MuiTabs-vertical .MuiTab-root.Mui-selected': {
          background: 'rgba(100,100,255,0.1)',
        },
      },
    },
    MuiSnackbar: {
      root: {
        '& .MuiPaper-root': {
          display: 'flex',
          alignItems: 'center',
          border: '1px solid rgb(100,100,101,0.3)',
        },
        '& .MuiAlert-message': {
          fontSize: '16px',
          lineHeight: 1,
        },
      },
    },
    MuiTableCell: {
      head: {
        '&&': {
          background: lighten('#7986cb', 0.85),
        },
      },
    },
    MuiTableRow: {
      head: {
        // background: 'grey',
      },
      root: {
        '&:nth-of-type(odd)': {
          // backgroundColor: 'white',
        },
        '&:nth-of-type(even)': {
          backgroundColor: 'rgba(236,236,236,0.93)',
        },
      },
    },
  },
})
export const APP_THEME = createTheme(APP_THEME_OPTIONS('standard'))
