export const getUrl = () => {
  if (typeof window === 'undefined') {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const { ENVIRONMENT } = require('../../scripts/dotenv')
    return ENVIRONMENT.URL
  } else {
    return window.location.protocol + '//' + window.location.host
  }
}
export const addLinkDomain = (link: string) => {
  return getUrl() + link
}
