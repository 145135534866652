import type { ParsedUrlQuery } from 'querystring'
import { useCallback, useMemo, useState } from 'react'
import { createContainer } from '../common/unstated-next'

const useLoadingState = () => {
  const [loadingStates, setLoadingStates] = useState<Record<string, boolean>>({})
  const setLoadingStart = useCallback((name: string) => {
    setLoadingStates((prevState) => ({ ...prevState, [name]: true }))
  }, [])
  const setLoadingEnd = useCallback((name: string) => {
    setLoadingStates((prevState) => ({ ...prevState, [name]: false }))
  }, [])
  return useMemo(
    () => ({
      setLoadingStart,
      setLoadingEnd,
      loading: Object.values(loadingStates).some((v) => v),
    }),
    [loadingStates, setLoadingEnd, setLoadingStart]
  )
}

const useAppContextContainer = (
  {
    query,
    error,
    setError,
  }: {
    query: ParsedUrlQuery
    error?: null | Error
    setError?: (e?: Error) => void
  } = {
    query: {},
  }
) => {
  const [componentStates, setComponentStates] = useState<Record<string, any>>({})
  const { setLoadingStart, setLoadingEnd, loading } = useLoadingState()
  return useMemo(
    () => ({
      query,
      error,
      setError,
      componentStates,
      setComponentStates,
      loading,
      setLoadingStart,
      setLoadingEnd,
    }),
    [query, error, setError, componentStates, loading, setLoadingStart, setLoadingEnd]
  )
}

export const useCurrentQueryVar = (name: string) => {
  const res = useCurrentQuery()[name]
  if (Array.isArray(res)) return undefined
  return res
}

export const AppContext = createContainer(useAppContextContainer)

export const useCurrentQuery = () => AppContext.useContainer().query || {}
export const useAppContext = AppContext.useContainer.bind(AppContext)
